<template>
  <div id="shares">
    <vs-progress class="w-full" v-if="!loaded" indeterminate color="primary"></vs-progress>
    <div v-else>
      <vs-table :data="shares" pagination :max-items="itemsPerPage" search>
        <template slot="thead">
          <vs-th sort-key="sql_timestamp">{{ $t('date') }}</vs-th>
          <vs-th sort-key="user_email">{{ $t('user_email') }}</vs-th>
          <vs-th sort-key="hash">{{ $t('hash') }}</vs-th>
          <vs-th sort-key="portal_slug">{{ $t('portal_slug') }}</vs-th>
          <vs-th sort-key="projects">{{ $t('projects') }}</vs-th>
          <vs-th sort-key="expire_date">{{ $t('expire_date') }}</vs-th>
          <vs-th sort-key="use_limit">{{ $t('use_limit') }}</vs-th>
          <vs-th sort-key="uses">{{ $t('uses') }}</vs-th>
          <vs-th sort-key="password_protected">{{ $t('password_protected') }}</vs-th>
          <vs-th>{{ $t('actions') }}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" :state="shareStatusColor(data[indextr])">
            <!-- sql_timestamp  -->
            <vs-td :data="data[indextr].sql_timestamp ">
              <span>{{data[indextr].sql_timestamp }}</span>
            </vs-td>
            <!-- user_email -->
            <vs-td :data="data[indextr].user_email">
              <span>{{data[indextr].user_email}}</span>
            </vs-td>
            <!-- hash -->
            <vs-td :data="data[indextr].hash">
              <span>{{data[indextr].hash}}</span>
            </vs-td>
            <!-- portal_slug -->
            <vs-td :data="data[indextr].portal_slug">
              <span>{{data[indextr].portal_slug}}</span>
            </vs-td>
            <!-- projects -->
            <vs-td :data="data[indextr].caps">
              <vs-chip v-for="(project_slug, index) in projectsSlugFromCaps(data[indextr].caps)" :key="index">
                <a class="text-white" :href="`/admin/project/${project_slug}`" target="_blank" rel="nofollow">{{ project_slug }}</a>
              </vs-chip>
            </vs-td>
            <!-- expire_date -->
            <vs-td :data="data[indextr].expire_date">
              <span>{{data[indextr].expire_date}}</span>
            </vs-td>
            <!-- use_limit -->
            <vs-td :data="data[indextr].use_limit">
              <span>{{data[indextr].use_limit}}</span>
            </vs-td>
            <!-- uses -->
            <vs-td :data="data[indextr].uses">
              <span>{{data[indextr].uses}}</span>
            </vs-td>
            <!-- password_protected -->
            <vs-td :data="data[indextr].password_protected">
              <span>
                <vs-icon v-if="data[indextr].password_protected == '1'" icon-pack="feather" icon="icon-check" />
              </span>
            </vs-td>
            <!-- actions -->
            <vs-td :data="data[indextr].hash">
              <a :href="`/admin/share/${data[indextr].hash}`" target="_blank" rel="nofollow">{{ $t("edit") }}</a>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </div>
</template>
<script>
import axios from '@/axios.js'
import vSelect from 'vue-select'
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from 'apexcharts'

import Datepicker from 'vuejs-datepicker'

export default {
  components: {
    moment,
    VueApexCharts, 
    vSelect, 
    Datepicker
  },
  data () {
    return {
      apexChatData,
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      appConfig: this.$appConfig,
      shares: {},
      itemsPerPage: 50,
      loaded: false
    }
  },
  methods: {   
    refreshAll () {
      this.getSharesInfos()
    },
    projectsSlugFromCaps (caps) {
      let jsonCaps = {}
      if (caps.includes('{')) {
        jsonCaps = JSON.parse(caps)
      } else {
        jsonCaps = JSON.parse(decodeURIComponent(caps))
      }
      return jsonCaps.map(value => {
        return value.project_slug
      })
    },
    getSharesInfos () {
      const params = ''
      const rimnat_api_call_url = `${this.api_server_baseurl}/admin/share/getSharesSynthesis?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          const shares = response.data
          for (let i = 0; i < shares.length; i++) {
            // convert string to int to allow sort in table
            shares[i].share_size = parseInt(shares[i].share_size)
            shares[i].projects_count = parseInt(shares[i].projects_count)
            if (shares[i].conversion_end_date && shares[i].conversion_end_date.length >= 10) shares[i].conversion_end_date = parseInt(shares[i].conversion_end_date.substring(0, 10).replaceAll('-', ''))
          }
          this.shares = shares
          this.loaded = true
        })
        .catch((error)   => { console.log(error) })
    },
    getSizeColor (num) {
      if (num > 80)  return 'danger'
      if (num > 60)  return 'warning'
      if (num >= 40) return 'primary'
      if (num < 40)  return 'success'
      return 'primary'
    },
    shareStatusColor (share) {
      let color = ''
      if (moment().subtract(1, 'd').isSameOrAfter(share.expire_date)) color = 'danger'
      if (share.use_limit > 0 && share.uses >= share.use_limit) color = 'warning'
      return color
    }
  },
 
  computed: {

  },

  beforeMount () {
  },

  mounted () {
    document.body.classList.add('theme-dark')
    this.refreshAll()
  },

  watch: {
    scale () {
      this.refreshAll()
    },
    start () {
      this.refreshAll()
    },
    end () {
      this.refreshAll()
    }
  }
}

</script>
<style lang="scss">

.theme-dark input {
    background: none !important;
}
</style>
